.contact--form{
   width: 40%;
   margin: 1rem auto 2rem;
       border-radius: 15px;
}
label{
   text-align: left;
}
.imputs{
   background-color: var(--primary);
   box-shadow: var(--shadow);
   padding: 2rem;
   text-align: left;
   border-radius: 9px;
}
#light .imputs {
   background-color: var(--primarylight);
   color: var(--lighttext);
   box-shadow: var(--lightshadow);
}
input, select, textarea{
   width: 100%;
   color: var(--primarytxt);
   background-color: var(--primaryblack);
   border: 1px solid var(--primarytxt);
   padding: 1rem 2rem;
   margin: 8px 0;
   border-radius: 4px;
}
input:focus, select:focus, textarea:focus{
      outline: none;
}
#light input,#light select,#light  textarea{
   background-color: var(--lightcontactbox);
   border: 1px solid var(--lighttext);
   color: var(--lighttext);
}
#light input:focus,#light select:focus,#light  textarea:focus{
   outline: none;
}
option{
   font-size: 15px;
   padding: 2rem;
   font-weight: 700;
}
#light option{
   background-color: var(--primarylight);
}
input[type=text]{
   text-transform: capitalize;
}
 textarea{
   height: 80px;
   font-size: 17px;
 }
 .form-submit {
   width: max-content;
   background-color: var(--primarytxt);
   color: var(--primaryblack);
   padding: 1rem 2rem;
   font-size: 15px;
   font-weight: 600;
   margin: 8px 0;
   border: none;
   border-radius: 4px;
   cursor: pointer;
   border: 1px solid var(--primarytxt);
 }
 #light .form-submit{
   background-color: var(--lightcontactbox);
   border: 1px solid var(--lighttext);
   color: var(--lighttext);
 }
 .form-submit:hover{
   background-color: var(--primaryblack);
   color: var(--primarytxt);
   transition: all .5s ease-in-out;
 }
 #light  .form-submit:hover{
   background-color: var(--primarylight);
   color: var(--lighttext);
   opacity: .7;
 }
 .social{
   display: none;
 }

 /* ====== MEDIA QUERIES ====== */
@media(max-width:900px){
   .contact--form{
      width: 90% !important;
      margin-bottom: 0;
      padding-bottom: 5rem;
   }
   .btm{
      padding-bottom: 3rem !important;
   }
   .social{
      display: flex;
      opacity: 1;
      margin: 2rem auto 0;
      justify-content: center;
      background: var(--primary);
      box-shadow: var(--shadow);
      width: 100%;
      padding: 1rem 2rem;
   }
   #light .social{
      background: var(--primarylight);
      box-shadow: var(--lightshadow);
   }
}
@media(max-width:500px){
   .contact--form{
      width: 100%;
      margin: auto;
   }
   form{
      width: 100%;
      margin: auto;
   }
}