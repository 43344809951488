.project{
   width: 90%;
   margin:1rem auto;
   box-shadow: var(--shadow);
   border-radius: 9px;
   overflow: hidden;
   margin-bottom: 50px;
}
#light .project {
   box-shadow: var(--lightshadow);
}
.project--img{
   width: 100%;
   height: 390px;
}
.paddinggg {
   padding: 2rem;
   display: grid;
   gap: 20px;
}
.project--img:hover{
   filter: sepia(50%) ;
}
.project--content{
   background: var(--primary);
   display: grid;
   justify-content: center;
   align-items: center;
   gap: 10px;
   position: relative;
   min-height: 700px;
}
#light .project--content{
   background: var(--primarylight);
   color: var(--lighttext) !important;
}
.project--links-div{
   width: 100%;
   display: flex;
   justify-content: space-between;
}
.project--link{
   border: 1px solid  var(--primarytxt);
   padding: .5rem 1rem;
   border-radius: 10px 20px;
}
#light .project--link{
   color: var(--lighttext) !important;
   border:1px solid var(--lighttext);
}
@media (max-width:600px){
   .project{
      grid-template-columns: 1fr;
   }
   .project--content{
      min-height: max-content;
   }
   .project--img {
      height: auto;
   }
   .paddinggg {
      padding: .6rem .6rem 1.5rem;
      gap: 10px;
   }
}