.toolset{
   background: var(--primary);
   width: 90%;
   margin:30px auto;
   height: max-content;
   text-align: center;
   font-size: 20px;
   padding: 2rem;
   box-shadow: var(--shadow);
   border-radius: 9px;
}
#light .toolset{
   background: var(--primarylight);
   box-shadow: var(--lightshadow);
}
hr{
   border: 2px solid var(--primarytxt);
}
#light hr{
   border: 2px solid var(--lighttext);
}
.toolset >h3{
   font-size: 30px;
   padding: 1rem;
}
.toolset > div{
   display: grid;
   grid-template-columns: repeat(3,1fr);
   justify-content: center;
   align-items: center;
   margin: auto;
   padding:1rem .5rem;
}
.skill--text{
   font-size: 15px;
}
.skilldescription > i{
   font-size: 70px;
}

@media(max-width:900px){
   .toolset > div > i{
      font-size: 50px;
   }
}