
.hero-body{
  background-position: 100vw;
  background-image: url(../images/introimg.jpg);
  fill-opacity: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 92vh;
  display: grid;
  margin: 0;
  padding: 0;
  align-items: center;
  position: relative;
  top: -40px;
}
.heromain{
   display: flex;
   margin: auto;
   height: inherit;
   flex-direction: column;
   align-items: center;
   padding: 10rem 0;
   width: 100%;
}
.hero--text{
  letter-spacing: 2px;
  font-size: 40px;
  line-height: 40px;
  font-weight: 700;
  text-align: center;
  padding: 1rem;
  left: 60px;
}
.name--text--small{
   font-size: medium;
}
#dark .hero--text{
   color: var(--white);
}
.hero-button{
   display: flex;
   gap: 20px;
}

.action--btn{
   padding: .5rem 2rem;
   color: var(--white);
   font-weight: 600;
   box-shadow:0px 2px 2px var(--shadow);
   background-color: var(--primary);
   border-radius: 5px;
}
#light .action--btn{
   background-color: var(--primarylight);
   color: var(--lighttext);
   box-shadow: 0px 2px 2px var(--primarylight);
}
.avartar{
	width: 150px;
	height: 150px;
	box-sizing: border-box;
	border: 5px solid var(--primary);
	border-radius: 50%;
	overflow: hidden;
   align-self: center;
   box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
   transform: translatey(0px);
   animation: float 6s ease-in-out infinite;
   margin: auto;
}
/* ======AVATAR ANIMATION KEYFRAME====== */
@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}
/* ======ANIMATION END */
#light .avartar{
   border: 5px solid var(--primarylight);
}
.avatar--Img{
   width: 100%;
   height: inherit;
}
.avartar::after {
      content: '';
      width: 30px; height: 30px;
      border-radius: 100%;
      border: 6px solid var(--white);
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: ring 4s infinite;
}
@keyframes ring {
   0% {
     width: 30px;
     height: 30px;
     opacity: 1;
   }
   50% {
     width: 150px;
     height: 150px;
     opacity: 0;
   }
   100% {
      width: 30px;
      height: 30px;
      opacity: 1;
    }
}
@media (max-width:900px){
   .hero-body{
      height: 87vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    
    .hero--text{
      width: 70%;
      bottom: 132px;
      line-height: 30px;
      font-size: 27px;
      left: 20px;
    }
}
@media(max-width: 900px){
   .heromain{
      padding: 2rem 0 12rem;
   }
}
@media(max-width: 500px){
   .hero--text{
      font-size: 20px;
   }
}