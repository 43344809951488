.projectPageDiv{
   display: grid;
   grid-template-columns: repeat(2, 1fr);

}

@media (max-width:900px){
   .projectPageDiv{
      grid-template-columns: 1fr;
      padding-bottom: 100px;
      width: 90%;
      margin: auto;
   }
}