@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Pacifico&family=Square+Peg&display=swap');
nav{
   background: var(--primary);
}
#light nav{
   background: var(--primarylight);
   color: var(--lighttext);
}
.navdiv{
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: auto;
   padding: .7rem 1rem 0;
}
.myLogo{
   width: 150px;
   user-select: none;
   cursor: pointer;
}
/* .logo{
   font-family: 'Square Peg';
   font-size: 50px;
   padding-bottom: 1rem;
   cursor: pointer;
} */
ul{
   display: flex;
   align-items: flex-end;
   gap: 2rem;
   list-style: none;
}

/* ====== logo design ====== */
.tt{
   font-family: 'Great Vibes', sans-serif;
}
a{
   color: var(--primarytxt);
}
#light a {
   color: var(--lighttext);
}
.icons{
   display: flex;
   gap: 7px;
   font-size: 18px;
   justify-content: center;
   align-items: center;
   padding: .2rem .2rem .5rem;
}
#light .icons{
   color: var(--lighttext);
}

ul a.active{
   border-bottom: 2px solid var(--primarytxt);
   margin: 0;
}
#light ul a.active{
   border-bottom: 2px solid var(--lighttext);
   margin: 0;
}
@media (max-width:900px){
   nav{
      flex-direction: column;
      width: 100%;
   }
   .logocv{
      width: 100%;
   }
   .icons{
      flex-direction: column;
      margin: auto;
      padding: 10px;
   }
   a.active{
      border-radius: 30px;
      border: 1px solid var(--primarytxt) !important;
   }
   #light a.active{
      border: 1px solid var(--lighttext) !important;
   }
   .icons > p{
      font-size: 11px;
   }  
   .icons > i{
      font-size: 22px;
   }
   ul{
      position: fixed;
      gap: .5rem;
      bottom: 5px;
      left: 50%;
      border-radius: 30px 30px 0 0;
      align-items: center;
      justify-content: center;
      width: max-content;
      padding: .5rem 1rem;
      width: max-content;
      left: 0;
      right: 0;
      margin: 0 auto;
      box-shadow: 0px -1px .1px var(--primarytxt);
      border-bottom:1px solid var(--primarytxt);
      background-color: var(--primary);
      z-index: 30;
      line-height: 1px;
   } 
   #light ul{
      box-shadow: 0px -1px .1px var(--lighttext);
      border-bottom:1px solid var(--lighttext);
      background-color: var(--primarylight);
   }
}

@media(max-width:500px){
   nav{
      display: block;
      margin: auto;
   }
}