.card{
   max-width: 1440px;
   position: relative;
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   align-items: flex-start;
   padding: 2rem 0;
   margin: auto;
}
@media(max-width:900px){
   .card{
      grid-template-columns: auto;
      gap: 0;
   }
}
