@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

/* ======TAILWIND CSS====== */
/* @tailwind base;
@tailwind components;
@tailwind utilities; */

:root{
  --shadow: 0px 2px 2px #E2E6E9;
  --lightshadow: 0px 2px 2px #36E7F7;
  --txtshadow: -2px 2px 4px rgb(184, 170, 170);
  --primary: #202124;
  --lightcontactbox: #4F778859;
  --primarylight: #1D3324;
  --lighttext: #36E7F7;
  --primarytxt: #E2E6E9;
  --darkbglighter: #3C4042;
  --primaryblack: #141010;
  --white: #fff;
  --black: #83899a;
  --ligthboxshadow: 0px 2px 2px #455A64;
}

*{
  margin: 0;
  box-sizing: border-box;
  max-width: 100%;
}
body{
  color: var(--primarytxt);
  font-family: -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  line-height: 30px;
}
#dark {
  background-color: var(--darkbglighter);
  transition: all .5s ease-in-out;

}
#light{
  background-color: var(--white);
  color: var(--lighttext);
  transition: all .5s ease-in;
}
/* ====== TOGGLE SWITCH STYLING ====== */
#dark .react-switch-bg{
  background-color: var(--darkbglighter) !important ;
  border: 2px solid var(--primarytxt) !important;
  transition: all .2s linear !important;
}
#dark .react-switch-handle{
  background-color: var(--primary) !important;
  border: 2px solid var(--primarytxt) !important;
  border-left: 2px solid var(--darkbglighter) !important ;
  box-shadow: none !important;
}
#light .react-switch-bg{
  background-color: var(--primarylight) !important ;
  border: 2px solid var(--lighttext) !important;
  transition: all .2s step-end !important;
}
#light .react-switch-handle{
  background-color: var(--primarylight) !important;
  border-bottom: 2px solid var(--white)!important;
  border-top: 2px solid var(--white)!important;
  border-right: 2px solid var(--darkbglighter) !important ;
  border-left: 2px solid var(--lighttext)!important;
  box-shadow: none !important;
}

/* ====== END OF TOGGLE SWITCH STYLING ====== */
.App{
  margin: 0 auto;
  border-radius: 10px;
}
.ccontact{
  max-width: 1440px;
  margin: auto;
}
.myabout{
  margin: -10px auto 10px !important;
}
.switch{
  position: absolute;
  top: -45px;
  left: 45%;
  margin: auto;
  z-index: 9999999999999;
}
.image--about{
  display: flex;
  justify-content: center;
}
.thank-you-home{
  padding: 1rem;
  text-align: center;
  display: grid;
  gap: 10px;
  margin: 10px auto  ;
  width: 50%;
  background-color: var(--primary);
}
#light .thank-you-home{
  background-color: var(--primarylight);
}
/* ======== INVALID PAGE DESIGN====== */
.invalid{
  width: 80%;
  height: 68vh;
  margin: 18px auto;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
  text-transform: capitalize;
  background: var(--primary);
  box-shadow: var(--shadow);
}
#light .invalid{
  background-color: var(--primarylight);
}
.invalid--img{
  width: 30%;
  margin: auto;
}
.readmore{
  font-size: 14px;

}
#light .abt{
  background: var(--primarylight);
  box-shadow: 0 2px 2px var(--black);
}
.abt--paragraph{
  display: block;
  text-align: left;
  margin: .5rem 0;
}
.abt--bold{
  font-weight: 900;
  padding: .5rem;

}

.tpl{
  display: block;
  padding: 0;
}
.viewmore{
  background: var(--primary);
  position: absolute;
  width: max-content;
  left: 0;
  right: 0;
  margin: auto;
}
#light .viewmore{
  background: var(--primarylight);
}
a{
  text-decoration: none;
}
.aaa{
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 9px 13px 9px 11px;
  border-radius: 6px;
  gap: 8px;
  text-decoration: none;
  box-shadow: var(--shadow);
}
#light .aaa{
  box-shadow: var(--lightshadow);
}


.about--img{
  width: 180px;
  margin:1rem auto 0 !important;
  border-radius:50%;
  animation: about--img infinite 10s ease-in-out;
}

.abt--links{
  background-color: var(--primaryblack);
  color: var(--primarytxt);
  font-size: 14px;
  padding: .3rem 1rem;
  border-radius: 2rem;
}

#light .abt--links{
  color: var(--lighttext);
}
.footer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--primary);
  padding-bottom: 1rem ;
  bottom: 0;
}
#light .footer{
  background-color: var(--primarylight);
}
footer{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .6rem;
  border-radius: 0px 0px 10px 10px;
}
small{
  text-align: center;
}

/* ====== MEDIA QUERIES ====== */
@media (max-width:900px){
  body{
    background-size: 70%;
    background-repeat: repeat;
    background-position: center;
  }
  #light{
    background-size: 70%;
    background-repeat: repeat;
    background-position: center;
  } 
  .switch{
    top: -50px;
    left: 82%;
  }
  .App{
    width: 100%;
    background: inherit;
  }
  .thank-you-home{
    width: 80%;
  }
  .int{
    height: 0;
  }
  .footer{
    display: none;
  }
  .int{
    height: 40px;
  }
  .bottomPad{
    padding-bottom: 6rem !important;
  }
}
@media (max-width:500px){
  .hero--text{
    bottom: 150px;
  }
  .thank-you-home{
    width: 100%;
    padding: 2rem 3rem;
    margin-bottom: 4rem;
  }
  .App{
    width: 100%;
    margin: 0;
    height: max-content;
    padding: 0;
  }
  .tpl{
    font-size: 13px;
  }
 .myabout{
  margin-top: 100px;
  margin-bottom: 200px;
 }
 
}


@keyframes about--img {
	0% {
		transform: translatex(0px);
	}
	25% {
		transform: translatex(-40px);
	}
  75%{
    transform: translateX(80px );
  }
	100% {
		transform: translatex(0px);
	}
}